<template>
  <b-modal
    id="ModalOnBoarding"
    ref="ModalOnBoarding"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="lg"
    centered
  >
    <div
      v-if="stepOnboarding === 0"
    >
      <b-row class="text-center justify-content-center mt-1 mb-2">
        <h1
          style="font-size: 32px;"
          class="text-black mb-2"
        >
          <strong>
            Halo, Partner!
            <br>
            Selamat datang di Kompack!
          </strong>
        </h1>
        <span class="text-black">
          Untuk membantumu lebih mengenal Kompack dan segera merasakan kemudahannya, yuk baca artikel singkat ini untuk mengenal fitur pokok Kompack
        </span>
      </b-row>
      <b-row class="justify-content-center">
        <b-img src="https://storage.googleapis.com/komerce/assets/svg/imgstep1.svg" />
      </b-row>
      <b-row class="justify-content-end mt-1 mb-2">
        <b-button
          variant="primary"
          class="d-flex align-items-center justify-content-center btn-icon mr-5"
          @click="handleNextStepOnboarding"
        >
          Mulai Membaca <b-img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/arrow-right.svg"
            class="ml-50"
          />
        </b-button>
      </b-row>
    </div>
    <div v-if="stepOnboarding >= 1">
      <b-overlay
        variant="light"
        :show="loadContentOnboarding"
        spinner-variant="primary"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <b-row
          class="mb-5"
        >
          <div class="header">

            <div class="inner-header flex">
              <b-row
                v-for="(item, index) in stepItem"
                :key="index+1"
                class="justify-content-center align-items-center"
              >
                <div style="position: relative;">
                  <span
                    v-if="stepOnboarding === item.value"
                    class="text-white"
                    style="position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 9;
                    transform: translate(-50%, -50%);"
                  >{{ item.value }}</span>
                  <b-img
                    v-if="stepOnboarding > item.value"
                    class="stepper__onboarding__mobile"
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/tick-circle.svg"
                    style="position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 9;
                    transform: translate(-50%, -50%);"
                  />
                  <div>
                    <b-img
                      v-if="stepOnboarding >= item.value"
                      class="stepper__onboarding__mobile"
                      src="https://storage.googleapis.com/komerce/assets/svg/wrapper__stepper.svg"
                    />
                    <b-img
                      v-if="stepOnboarding < item.value"
                      class="stepper__onboarding__mobile"
                      src="https://storage.googleapis.com/komerce/assets/svg/wrapper__stepper__inactive.svg"
                    />
                  </div>
                </div>
                <span
                  v-if="index !== stepItem.length - 1"
                  class="process__stepper__onboarding__mobile"
                  :style="stepOnboarding <= item.value ? 'width: 70px; height: 2px; background: #E2E2E2;' : 'width: 70px; height: 2px; background: #F95031;'"
                />
              </b-row>
            </div>
            <!-- Pengaturan -->
            <b-img
              v-if="showPengaturan()"
              src="https://storage.googleapis.com/komerce/assets/svg/imgstep2.svg"
              class="wrapper__image__stepper wrapper__image__stepper__mobile"
            />
            <!-- Tambah Produk -->
            <b-img
              v-if="showTambahProduk()"
              src="https://storage.googleapis.com/komerce/assets/svg/imgstep3.svg"
              class="wrapper__image__stepper wrapper__image__stepper__mobile"
            />
            <!-- Ajukan Layanan Mitra Gudang -->
            <b-img
              v-if="showAjukanLayanan()"
              src="https://storage.googleapis.com/komerce/assets/svg/Ajukan-Layanan.svg"
              class="wrapper__image__stepper wrapper__image__stepper__mobile"
            />
            <!-- Tunggu proses pengajuan  -->
            <b-img
              v-if="showTungguProses()"
              src="https://storage.googleapis.com/komerce/assets/svg/Proses-pengajuan.svg"
              class="wrapper__image__stepper wrapper__image__stepper__mobile"
            />
            <!-- Inbound  -->
            <b-img
              v-if="showInbound()"
              src="https://storage.googleapis.com/komerce/assets/svg/Inbound-truck.svg"
              class="wrapper__image__stepper wrapper__image__stepper__mobile"
            />
            <!-- Outbound  -->
            <b-img
              v-if="showOutbound()"
              src="https://storage.googleapis.com/komerce/assets/svg/outbond-Truck.svg"
              class="wrapper__image__stepper wrapper__image__stepper__mobile"
            />
            <!-- Tambah Order -->
            <b-img
              v-if="showTambahOrder()"
              src="https://storage.googleapis.com/komerce/assets/svg/imgstep4.svg"
              class="wrapper__image__stepper wrapper__image__stepper__mobile"
            />
            <!-- Ajukan Pickup -->
            <b-img
              v-if="showAjukanPickup()"
              src="https://storage.googleapis.com/komerce/assets/svg/imgstep5.svg"
              class="wrapper__image__stepper wrapper__image__stepper__mobile"
            />
            <!-- Roket -->
            <b-img
              v-if="stepOnboarding === stepItem.length"
              src="https://storage.googleapis.com/komerce/assets/svg/imgstep6.svg"
              class="wrapper__image__stepper__last wrapper__image__stepper__mobile"
            />

            <div>
              <svg
                class="waves"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 24 150 28"
                preserveAspectRatio="none"
                shape-rendering="auto"
              >
                <defs>
                  <path
                    id="gentle-wave"
                    d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                  />
                </defs>
                <g class="parallax">
                  <use
                    xlink:href="#gentle-wave"
                    x="48"
                    y="0"
                    fill="rgba(255,255,255,0.7"
                  />
                  <use
                    xlink:href="#gentle-wave"
                    x="48"
                    y="3"
                    fill="rgba(255,255,255,0.5)"
                  />
                  <use
                    xlink:href="#gentle-wave"
                    x="48"
                    y="5"
                    fill="rgba(255,255,255,0.3)"
                  />
                  <use
                    xlink:href="#gentle-wave"
                    x="48"
                    y="7"
                    fill="#fff"
                  />
                </g>
              </svg>
            </div>

          </div>
        </b-row>
        <b-row class="mb-1">
          <b-col>
            <h1 class="text-black mb-1 mt-3">
              <strong>
                {{ getTitle() }}
              </strong>
            </h1>
            <!-- Pengaturan -->
            <span
              v-if="showPengaturan()"
              class="text-black"
              style="font-size: 15px;"
            >
              kamu bisa langsung lengkapi data-data di menu pengaturan seperti <strong><i>data profile</i></strong>, <strong><i>alamat pickup</i></strong>, <strong><i>aktifkan ekspedisi</i></strong>, <strong><i>rekening bank</i></strong>, dll data tersebut <strong><i>wajib diisi</i></strong> serta merta agar proses seperti menambahkan order atau <strong><i>menarik penghasilan</i></strong> lancar kedepannya.
            </span>
            <!-- Tambah Produk -->
            <b-col
              v-if="showTambahProduk()"
              cols="12"
              class="pl-0"
            >
              <span
                class="text-black"
                style="font-size: 15px;"
              >
                Mengapa perlu menambahkan Produk?
              </span>
              <ul
                class="text-black ml-1 my-50"
                style="font-size: 15px; list-style: initial;"
              >
                <li>
                  Agar kamu dapat menganalisa mana <strong>produk dan varian yang paling laku</strong> yang udah kamu jual selama ini.
                </li>
                <li>
                  Agar kamu <strong>tidak perlu input ulang</strong> produkmu saat menambahkan order.
                </li>
                <li>
                  Agar detail produk muncul di <strong>label pengiriman</strong>.
                </li>
              </ul>
              <span
                class="text-black"
                style="font-size: 15px;"
              >
                Karena keuntungan kedepannya yang <strong>sangat banyak</strong>, jadi bersemangatlah input produk yuk kak!
              </span>
            </b-col>
            <!-- Ajukan Layanan Mitra Gudang -->
            <b-col
              v-if="showAjukanLayanan()"
              cols="12"
              class="pl-0"
            >
              <p
                class="text-black"
                style="font-size: 15px;"
              >
                Pilih mitra gudang sesuai kebutuhanmu untuk membantu menangani urusan simpan, packing, dan kirim orderanmu.
              </p>
              <p
                class="text-black"
                style="font-size: 15px;"
              >
                Kamu bisa ke menu cari gudang untuk melihat semua gudang Kompack yang tersedia. kamu juga bisa melihat detail setiap gudang sebelum melakukan pengajuan berlangganan.
              </p>
            </b-col>
            <!-- Tunggu proses pengajuan  -->
            <b-col
              v-if="showTungguProses()"
              cols="12"
              class="pl-0"
            >
              <p
                class="text-black"
                style="font-size: 15px;"
              >
                Admin kami perlu melakukan review dan penentuan biaya fulfillment untuk produk yang kamu ajukan.
                sabar ya...
              </p>
              <p
                class="text-black"
                style="font-size: 15px;"
              >
                Tenang aja, prosesnya tidak akan lama. pengajuanmu akan diproses <b>maksimal 2x24 jam</b>. Kamu bisa pantau status pengajuanmu di menu riwayat pengajuan atau kamu juga bisa chat admin untuk followup pengajuanmu melalui livechat.
              </p>
            </b-col>
            <!-- Inbound -->
            <b-col
              v-if="showInbound()"
              cols="12"
              class="pl-0"
            >
              <p
                class="text-black"
                style="font-size: 15px;"
              >
                Setelah berlangganan gudang, kamu bisa mulai <b>menyimpan barang di gudang</b> yang telah kamu pilih dengan cara melakukan pengajuan inbound. pastikan kamu telah melakukan pengajuan inbound sebelum mengirimkan barang fisik ke gudang agar proses inboundmu bisa diproses oleh gudang.
              </p>
              <p
                class="text-black"
                style="font-size: 15px;"
              >
                Oiya, jangan lupa perhatikan waktu estimasi sampai gudang ya. usahakan barangmu sampai di gudang saat masih jam operasional gudang agar inboundmu dapat segera diproses oleh mitra Kompack.
              </p>
            </b-col>
            <!-- Outbound -->
            <b-col
              v-if="showOutbound()"
              cols="12"
              class="pl-0"
            >
              <p
                class="text-black"
                style="font-size: 15px;"
              >
                Outbound adalah proses mengeluarkan barang dari gudang ketika kamu ada orderan dari customermu. kamu hanya perlu melakukan proses buat order dan pengajuan pick-up seperti yang sudah biasa kamu lakukan di Komship. Tenang saja, data orderanmu akan <b>langsung terkirim ke mitra gudang kompack</b> untuk segera di proses
              </p>
              <p
                class="text-black"
                style="font-size: 15px;"
              >
                Pastikan <b>saldomu</b> cukup ya untuk membayar biaya fulfillment agar pengajuan pickupmu berhasil. Untuk melihat mutasi biaya fulfillment kamu bisa cek di rincian saldo.
              </p>
            </b-col>
            <!-- Tambah Order -->
            <b-col
              v-if="showTambahOrder()"
              cols="12"
              class="pl-0"
            >
              <p
                class="text-black"
                style="font-size: 15px;"
              >
                Saatnya mulai masuk-masukin <strong>data order</strong> nih ke Komship. Data yang dimasukin standar aja seperti nama customer, alamat, belinya apa, dikirim dari mana, jumlahnya berapa, COD atau bukan, dll.
              </p>
              <p
                class="text-black"
                style="font-size: 15px;"
              >
                Untuk <strong>orderan jenis COD</strong> kamu bisa mempercepat proses input dengan mengaktifkan fitur pendukung <strong>"input cepat"</strong> agar dapat cukup masukin <strong>nilai COD/nilai transaksi</strong> aja.
              </p>
            </b-col>
            <!-- Ajukan Pickup -->
            <b-col
              v-if="showAjukanPickup()"
              cols="12"
              class="pl-0"
            >
              <p
                class="text-black mb-50"
                style="font-size: 15px;"
              >
                Setelah berhasil input semua <strong>orderan yang mau dikirim</strong>, saatnya <strong>ajukan pickup</strong>. Kakak tinggal pickup orderan dari <strong>gudang-gudang yang kakak pilih</strong>. Setelah ajukan pickup berhasil, nomor <strong>resi akan keluar otomatis</strong> dan untuk pengajuan pertama kali biasanya akan ada kurir ekspedisi akan mengontak nomor HP yang kakak cantumkan pada pengaturan <strong>penanggung jawab gudang</strong>.
              </p>
              <p
                class="text-black"
                style="font-size: 15px;"
              >
                Label pengiriman untuk dicetak ada pada menu <strong>riwayat pickup</strong>.
              </p>
            </b-col>
            <!-- Roket -->
            <b-col
              v-if="stepOnboarding === stepItem.length"
              cols="12"
              class="pl-0"
            >
              <h5 class="text-black">
                <strong>
                  Hore... Kamu telah melewati sesi onboarding. Selamat!
                </strong>
              </h5>
              <p
                class="text-black mb-50"
                style="font-size: 15px;"
              >
                Pantaulah <strong>status pengiriman</strong> dari mulai order <i>dibuat, dipacking, dikirim dan diterima</i> pada menu <strong>Data Order</strong>. Kamu juga bisa lacak status pengiriman di menu detail order.
              </p>
              <p
                class="text-black"
                style="font-size: 15px;"
              >
                Jika mengalami kesulitan, jangan ragu untuk bertanya ke Customer Support kami melalui live chat ya...
              </p>
            </b-col>
          </b-col>
        </b-row>
        <b-row class="justify-content-end align-items-center pr-3 pb-1">
          <div>
            <b-img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/arrow-circle-left.svg"
              class="cursor-pointer"
              @click="handlePrevStepOnboarding"
            />
          </div>
          <div>
            <b-img
              v-if="stepOnboarding !== stepItem.length"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/arrow-circle-right.svg"
              class="cursor-pointer"
              @click="handleNextStepOnboarding"
            />
            <b-button
              v-if="stepOnboarding === stepItem.length"
              variant="primary"
              class="d-flex align-items-center justify-content-center btn-icon ml-1"
              :disabled="buttonStartIsDisabled"
              @click="updateProfileOnBoarding"
            >
              <b-spinner
                v-if="loadingOnboarding"
                small
                class="mr-50"
              />
              Mulai Sekarang <b-img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/arrow-right.svg"
                class="ml-50"
              />
            </b-button>
          </div>
        </b-row>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Onboarding',
  data() {
    return {
      stepped: 1,
      dataSteps: 1,
      disabledOnboardingMulai: false,

      stepOnboarding: 0,
      stepItem: [
        {
          value: 1,
        },
        {
          value: 2,
        },
        {
          value: 3,
        },
        {
          value: 4,
        },
        {
          value: 5,
        },
        {
          value: 6,
        },
        {
          value: 7,
        },
        {
          value: 8,
        },
      ],
      loadContentOnboarding: false,
      loadingOnboarding: false,
      buttonStartIsDisabled: false,
    }
  },
  computed: {
    ...mapState('dashboard', ['profile']),
  },
  mounted() {
    this.initOnboard()
    if (this.profile.is_onboarding === true) {
      this.stepItem.splice(-3)
    }
  },
  methods: {
    initOnboard() {
      const isKompack = this.profile.is_kompack
      const isOnboardingKompack = this.profile.is_onboarding_kompack

      if (isKompack === 1 && isOnboardingKompack === false) this.$refs.ModalOnBoarding.show()
    },
    handleStepOnboard(params) {
      switch (params) {
        case 'endsteponboarding':
          this.updateProfileOnBoarding()
          break
        default:
          this.stepped = params
          break
      }
    },
    updateProfileOnBoarding() {
      this.buttonStartIsDisabled = true
      this.disabledOnboardingMulai = false
      this.loadingOnboarding = true
      this.$http_komship
        .put('/v1/partner/onboarding/update', {})
        .then(resp => {
          if (resp.data.code === 200 && resp.data.status === 'success') {
            this.$http_komship.post('/v1/gamifaction/start')
              .then(() => {
                this.loadingOnboarding = false
                this.buttonStartIsDisabled = false
                this.$refs.ModalOnBoarding.hide()
                this.$store.dispatch('dashboard/getProfile')
              }).catch(() => {
                this.loadingOnboarding = false
                this.buttonStartIsDisabled = false
                window.location.reload()
              })
          }
        })
        .catch(err => {
          this.disabledOnboardingMulai = false
          this.loadingOnboarding = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
          this.buttonStartIsDisabled = false
        })
    },
    handleNextStepOnboarding() {
      this.loadContentOnboarding = true
      setTimeout(() => {
        this.stepOnboarding += 1
        this.loadContentOnboarding = false
      }, 500)
    },
    handlePrevStepOnboarding() {
      this.loadContentOnboarding = true
      setTimeout(() => {
        this.stepOnboarding -= 1
        this.loadContentOnboarding = false
      }, 500)
    },
    getTitle() {
      let title = ''
      if (this.showPengaturan())title = 'Pengaturan'
      else if (this.showTambahProduk())title = 'Tambah Produk'
      else if (this.showAjukanLayanan())title = 'Ajukan Layanan Mitra Gudang'
      else if (this.showTungguProses())title = 'Tunggu proses pengajuan'
      else if (this.showInbound())title = 'Inbound'
      else if (this.showOutbound())title = 'Outbound'
      else if (this.showTambahOrder())title = 'Tambah Order'
      else if (this.showAjukanPickup())title = 'Ajukan Pickup'
      else if (this.stepOnboarding === this.stepItem.length)title = 'Ayo Mulai Pakai Kompack'
      return title
    },
    showPengaturan() {
      if (this.profile.is_onboarding === true) {
        if (this.stepOnboarding === 1) return false
      } else if (this.stepOnboarding === 1) return true
      return false
    },
    showTambahProduk() {
      if (this.profile.is_onboarding === true) {
        if (this.stepOnboarding === 2) return false
      } else if (this.stepOnboarding === 2) return true
      return false
    },
    showAjukanLayanan() {
      if (this.profile.is_onboarding === true) {
        if (this.stepOnboarding === 1) return true
      } else if (this.stepOnboarding === 3) return true
      return false
    },
    showTungguProses() {
      if (this.profile.is_onboarding === true) {
        if (this.stepOnboarding === 2) return true
      } else if (this.stepOnboarding === 4) return true
      return false
    },
    showInbound() {
      if (this.profile.is_onboarding === true) {
        if (this.stepOnboarding === 3) return true
      } else if (this.stepOnboarding === 5) return true
      return false
    },
    showOutbound() {
      if (this.profile.is_onboarding === true) {
        if (this.stepOnboarding === 4) return true
      }
      return false
    },
    showTambahOrder() {
      if (this.profile.is_onboarding === true) {
        return false
      } if (this.stepOnboarding === 6) return true
      return false
    },
    showAjukanPickup() {
      if (this.profile.is_onboarding === true) {
        return false
      } if (this.stepOnboarding === 7) return true
      return false
    },
  },
}
</script>
  <style scoped>
  [dir] .wrapper__image__stepper {
      position: absolute;
      top: 100%;
      left: 50%;
      z-index: 9;
      transform: translate(-50%, -50%);
  }

  [dir] .wrapper__image__stepper__last {
      position: absolute;
      top: 100%;
      left: 80%;
      z-index: 9;
      transform: translate(-50%, -50%);
  }
  .header {
    position:relative;
    text-align:center;
    background: linear-gradient(60deg, rgb(255, 239, 239) 0%, rgb(255, 219, 219) 100%);
    color:white;
    width: 100%;
  }
  .logo {
    width:50px;
    fill:white;
    padding-right:15px;
    display:inline-block;
    vertical-align: middle;
  }
  .inner-header {
    height:20vh;
    width:100%;
    margin: 0;
    padding: 0;
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .waves {
    position:relative;
    width: 100%;
    height:15vh;
    margin-bottom:-7px;
    min-height:100px;
    max-height:150px;
  }
  .content {
    position:relative;
    height:20vh;
    text-align:center;
    background-color: white;
  }
  .parallax > use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
  @keyframes move-forever {
    0% {
     transform: translate3d(-90px,0,0);
    }
    100% {
      transform: translate3d(85px,0,0);
    }
  }
  @media (max-width: 768px) {
    .waves {
      height:40px;
      min-height:40px;
    }
    .content {
      height:30vh;
    }
    h1 {
      font-size:24px;
    }
  }
  @media (max-width: 576px) {
    .stepper__onboarding__mobile {
      width: 30px;
    }
    .process__stepper__onboarding__mobile {
      width: 40px!important;
    }
    .wrapper__image__stepper__mobile {
      width: 140px;
    }
  }
  </style>
